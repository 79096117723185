import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundFileGenerationForm from 'react/shared/card/components/BackgroundFileGenerationForm';
import bindAll from 'react/shared/utils/bind_all';

//TODO: Update to functional component and use useRef
export default class ClientStatementsByMonthForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: undefined,
    };

    bindAll(this);
  }

  handleSelectChange(ev) {
    this.setState({ selectedOption: ev.target.value });
  }

  render() {
    const { statementMonthsToShow, largeNumberOfAccounts } = this.props;
    const { selectedOption } = this.state;
    const modalText = largeNumberOfAccounts
      ? 'Please wait while your client statement files are generated. This is a large data request and may take several minutes. Please contact True Link Customer Service if there are issues.'
      : 'Please wait while your client statement files are generated...';
    return (
      <div id="client_statements" ref={(input) => (this.clientStatementsByMonthForm = input)}>
        <select
          id="clients_statements_month_select"
          onChange={this.handleSelectChange}
          style={{ marginRight: 18 }}
          value={selectedOption}
        >
          <option value="">Select month</option>
          {statementMonthsToShow.map((month) => (
            <option key={month} value={month}>
              {moment(month).format('MMMM YYYY')}
            </option>
          ))}
        </select>
        <BackgroundFileGenerationForm
          buttonText="Download as Zip"
          data={{ month: selectedOption }}
          downloadDisabled={!selectedOption}
          downloadRoute={RailsRoutes.all_client_statements_by_month_path}
          fileType="zip"
          largeNumberOfAccounts={largeNumberOfAccounts}
          modalText={modalText}
        />
      </div>
    );
  }
}

ClientStatementsByMonthForm.propTypes = {
  statementMonthsToShow: PropTypes.array.isRequired,
  largeNumberOfAccounts: PropTypes.bool.isRequired,
};
