import axios from 'axios';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import _isEmpty from 'underscore/modules/isEmpty';
import { DashboardCardContext } from 'react/member/card/components/onboarding_widget/DashboardCardContext';
import PopUp from 'react/shared/components/popups/PopUp';
import StateDropdown from 'react/shared/components/state_dropdown';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import CountryOfCitizenshipFormWrapper from 'react/shared/components/true_link/main/form/country_of_citizenship/CountryOfCitizenshipFormWrapper';
import { localeShapeKeys } from 'react/shared/shapes/locale_shape';
import bindAll from 'react/shared/utils/bind_all';

//TODO: Update to functional component and use useRef
export default class DashboardCardActivationForm extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (props.cardContext) {
      DashboardCardActivationForm.contextType = DashboardCardContext;
    } else {
      DashboardCardActivationForm.contextTypes = {
        toggleModal: PropTypes.func,
        accountId: PropTypes.string,
        onChangeCardholderAttr: PropTypes.func,
        onChangeAddressAttr: PropTypes.func,
        onClose: PropTypes.func,
        cardholder: PropTypes.object,
        address: PropTypes.object,
        bulkCardOrder: PropTypes.bool,
        secondaryDocumentation: PropTypes.bool,
        ...localeShapeKeys,
      };
    }

    this.state = {
      errorMessage: undefined,
    };

    bindAll(this);
  }

  componentDidMount() {
    $('i[data-toggle="tooltip"]').tooltip();
  }

  getCardActivationError(type) {
    const { supportADTollfree, supportEmail } = this.context;

    const errors = {
      invalid_card_last_4: {
        displayType: 'modal',
        message:
          'The last four digits of the True Link Card do not match. Please check the True Link dashboard to confirm you are activating the correct card.',
      },
      failed_idv_18_or_older: {
        displayType: 'modal',
        message: (
          <div>
            <div>
              We were unable to activate this card, as we could not verify the cardholder's identity
              using the automated system. Please review the information submitted and try again.
            </div>
            <br />
            <div>
              If you receive this message after confirming the information and retrying, this card
              requires manual activation. Please contact our Customer Support team directly at{' '}
              {supportADTollfree} or {supportEmail} for assistance activating this card.
            </div>
            <br />
            <div>
              <a
                href="https://help.admin.truelinkfinancial.com/hc/en-us/articles/27705981031323-Activate-a-True-Link-Visa-Prepaid-Card"
                rel="noreferrer"
                target="_blank"
              >
                Learn more about card activation
              </a>
            </div>
          </div>
        ),
      },
      failed_idv_under_18: {
        displayType: 'modal',
        message: (
          <div>
            <div>
              Please contact our Customer Support team directly at {supportADTollfree} or{' '}
              {supportEmail} for assistance activating this card.
            </div>
            <br />
            <div>
              <a
                href="https://help.admin.truelinkfinancial.com/hc/en-us/articles/27705981031323-Activate-a-True-Link-Visa-Prepaid-Card"
                rel="noreferrer"
                target="_blank"
              >
                Learn more about card activation
              </a>
            </div>
          </div>
        ),
      },
      default: {
        displayType: 'flash',
        message: $(`<span>
      This card could not be activated based on the information provided. Please call ${I18n.t(
        'corp.ad_tollfree_numeric',
      )} and we'll help get this card activated.
    </span>`),
      },
    };

    return errors[type] || errors.default;
  }

  getHeader() {
    if (this.context.secondaryDocumentation) {
      return 'Please be careful!';
    }

    return 'Activate Card';
  }

  getFooter() {
    return (
      <TrueLinkButton className="btn btn-success" onClick={this.submitForm} variant="none">
        Activate
      </TrueLinkButton>
    );
  }

  getPopUpProps() {
    return {
      header: this.getHeader(),
      footer: this.getFooter(),
      modalOpen: true,
      maxWidth: '960px',
      modalBodyClass: 'nopadding',
      onClose: this.props.onClose,
    };
  }

  submitForm(ev) {
    ev.preventDefault();

    let route;
    if (this.context.secondaryDocumentation) {
      route = RailsRoutes.no_idv_register_and_activate_path;
    } else {
      route = RailsRoutes.register_cardholder_and_activate_path;
    }

    const url = route({
      account_id: this.context.accountId,
      format: 'json',
    });
    const form = $('#account_list_activation_form');
    const rules = {
      'cardholder[address_attributes][street1]': {
        requireNonPOBox: true,
      },
      'cardholder[address_attributes][street2]': {
        requireNonPOBox: true,
      },
      'cardholder[dob]': {
        validDate: true,
      },
      'cardholder[ssn_raw]': {
        ssn: true,
      },
      'cardholder[card_last_4]': {
        cardLast4: true,
      },
    };
    form.validate({ rules });
    if (form.valid()) {
      const countryOfCitizenshipValue = $('input[name="countryOfCitizenship"]').val();
      const nonResidentAlienValue = $('input[name="nonResidentAlien"]:checked').val();
      if (
        countryOfCitizenshipValue &&
        countryOfCitizenshipValue != 'US' &&
        !nonResidentAlienValue
      ) {
        Truelink.flash('error', "You haven't provided non-resident alien status.");
        return; // Disables continue button if validations fail
      }
      $('#spinner, #overlay').show();

      const data = form.serializeArray();
      const contryOfCitizenshipIndex = data.findIndex(
        (item) => item.name === 'countryOfCitizenship',
      );
      if (contryOfCitizenshipIndex !== -1) {
        data.push({
          name: 'cardholder[country_of_citizenship]',
          value: data[contryOfCitizenshipIndex].value,
        });
        data.splice(contryOfCitizenshipIndex, 1);
      }

      const nonResidentAlienIndex = data.findIndex((item) => item.name === 'nonResidentAlien');
      if (nonResidentAlienIndex !== -1) {
        data.push({
          name: 'cardholder[non_resident_alien]',
          value:
            // eslint-disable-next-line no-nested-ternary
            data[nonResidentAlienIndex].value === 'yes'
              ? true
              : data[nonResidentAlienIndex].value === 'no'
                ? false
                : undefined,
        });
        data.splice(nonResidentAlienIndex, 1);
      }

      $.post(url, data)
        .fail(() => $('#spinner, #overlay').hide())
        .done((response) => {
          if (response.success) {
            this.checkForCardholderIDVStatus(response);
          } else {
            this.failedFormSubmit(response);
          }
        });
    }
  }

  failedFormSubmit(data) {
    $('#spinner, #overlay').hide();

    const error = this.getCardActivationError(data?.message);
    if (error.displayType === 'modal') {
      this.setState({ errorMessage: error.message });
    } else {
      Truelink.flash('error', error.message);
    }
  }

  checkForCardholderIDVStatus(data) {
    if (this.context.secondaryDocumentation) {
      // no need to check IDV status when using secondary docs
      this.props.onSuccess(data);
    } else {
      const url = RailsRoutes.cardholder_registered_path({
        account_id: this.context.accountId,
        format: 'json',
      });
      axios.get(url).then((response) => this.checkForSuccessOrRetry(response.data));
    }
  }

  checkForSuccessOrRetry(data) {
    if (data.success === true) {
      this.props.onSuccess(data);
    } else if (this.state.attempts < 160) {
      this.setState(({ attempts }) => ({ attempts: attempts + 1 }));
      this.checkForCardholderIDVStatus(data);
    } else {
      this.failedFormSubmit();
    }
  }

  closeErrorModal() {
    this.setState({ errorMessage: undefined });
  }

  activateField(e) {
    const $input = $(e.currentTarget);
    $input.data('placeholder', $input.attr('placeholder'));
    $input.attr('placeholder', '');
    const $label = $input.closest('label');
    if (!$label.hasClass('field-active')) {
      $label.removeClass('field-inactive').addClass('field-active');
    }
  }

  deactivateField(e) {
    const $input = $(e.currentTarget);
    $input.attr('placeholder', $input.data('placeholder'));
    const $label = $input.closest('label');
    if ($input.val().length === 0 && $label.hasClass('field-active')) {
      $label.addClass('field-inactive').removeClass('field-active');
    }
  }

  showCardholderNameInput() {
    return (
      this.context.bulkCardOrder ||
      this.context.cardholder?.first_name === null ||
      this.context.cardholder?.last_name === null
    );
  }

  renderCardholderNameSection() {
    const { cardholder, onChangeCardholderAttr } = this.context;
    if (this.showCardholderNameInput()) {
      return (
        <div className="card--form__section">
          <div className="card--form__row">
            <div className="card--form__col">
              <label className="card--form__floating-label" htmlFor="first_name">
                First Name
                <input
                  id="first_name"
                  name="cardholder[first_name]"
                  onBlur={this.deactivateField}
                  onChange={onChangeCardholderAttr}
                  onFocus={this.activateField}
                  placeholder="First Name"
                  required
                  type="text"
                  value={cardholder.first_name || ''}
                />
              </label>
            </div>
            <div className="card--form__col">
              <label className="card--form__floating-label" htmlFor="last_name">
                Last Name
                <input
                  id="last_name"
                  name="cardholder[last_name]"
                  onBlur={this.deactivateField}
                  onChange={onChangeCardholderAttr}
                  onFocus={this.activateField}
                  placeholder="Last Name"
                  required
                  type="text"
                  value={cardholder.last_name || ''}
                />
              </label>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="new-form__section new-form--compact">
        <div className="new-form__label">
          <label htmlFor="first_name">Name:</label>
        </div>
        <div className="new-form__data">
          {cardholder.first_name} {cardholder.last_name}
        </div>
      </div>
    );
  }

  renderCardholderAddressSection() {
    const { address, bulkCardOrder, onChangeAddressAttr, supportADTollfree, supportEmail } =
      this.context;
    if (bulkCardOrder || _isEmpty(address)) {
      return (
        <div className="card--form__section">
          <div className="card--form__row">
            <div className="card--form__col width-100">
              <label htmlFor="street1">
                Home Address
                <span className="card--form__input-addition">
                  Please no P.O. Boxes&nbsp;
                  <i
                    className="info-circle cursor-default"
                    data-placement="top"
                    data-template='<div class="tooltip" role="tooltip" style="width: 320px"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
                    data-toggle="tooltip"
                    title="Because this address is used for identity verification, a street address is required. We will not send anything to the street address unless you request that we do so."
                  />
                </span>
                <input
                  id="street1"
                  name="cardholder[address_attributes][street1]"
                  onChange={onChangeAddressAttr}
                  placeholder="Street Address"
                  required
                  type="text"
                  value={address.street1 || ''}
                />
              </label>
            </div>
          </div>

          <div className="card--form__row">
            <div className="card--form__col width-100">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="street2">
                <input
                  id="street2"
                  name="cardholder[address_attributes][street2]"
                  onChange={onChangeAddressAttr}
                  placeholder="Apartment, Unit or Suite (optional)"
                  type="text"
                  value={address.street2 || ''}
                />
              </label>
            </div>
          </div>
          <div className="card--form__row">
            <div className="card--form__col" style={{ width: '43%' }}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="city">
                <input
                  id="city"
                  name="cardholder[address_attributes][city]"
                  onChange={onChangeAddressAttr}
                  placeholder="City"
                  required
                  type="text"
                  value={address.city || ''}
                />
              </label>
            </div>
            <div className="card--form__col" style={{ width: '33%' }}>
              {}
              <label htmlFor="state">
                <StateDropdown
                  abbreviated
                  id="state"
                  name="cardholder[address_attributes][state]"
                  onChange={onChangeAddressAttr}
                  required
                  value={address.state || ''}
                  width={'100%'}
                />
              </label>
            </div>
            <div className="card--form__col" style={{ width: '22%' }}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="zip">
                <input
                  id="zip"
                  maxLength="5"
                  name="cardholder[address_attributes][zip]"
                  onChange={onChangeAddressAttr}
                  placeholder="Zip Code"
                  required
                  type="text"
                  value={address.zip || ''}
                />
              </label>
            </div>
          </div>
        </div>
      );
    }
    //moved here since single quote messes up code coloring in some editors
    const updateText = `If you need to update the cardholder's address, please email ${supportEmail} or call ${supportADTollfree}.`;
    return (
      <div className="new-form__section">
        <div className="new-form__label">Address:</div>
        <div className="new-form__data">{this.cardholderAddress()}</div>
        <div className="new-form__callout">{updateText}</div>
      </div>
    );
  }

  renderCardholderPhoneSection() {
    const { cardholder, onChangeCardholderAttr, supportCHTollfree } = this.context;
    return (
      <div className="card--form__section">
        <div className="card--form__row">
          <div className="card--form__col">
            <label htmlFor="mobile">
              Phone number
              <span className="card--form__input-addition">Optional</span>
              <ReactInputMask
                id="mobile"
                mask="(999) 999-9999"
                name="cardholder[mobile]"
                onChange={onChangeCardholderAttr}
                placeholder="(999) 999-9999"
                type="text"
                value={cardholder.mobile || ''}
              />
            </label>
          </div>
        </div>
        <div className="card--form__row">
          <p style={{ marginRight: 10 }}>
            <i className="info-circle cursor-default" />
          </p>
          <p style={{ fontStyle: 'italic' }}>
            Giving us the cardholder's phone number allows them to send a text to{' '}
            {supportCHTollfree} to check the balance on their card
          </p>
        </div>
      </div>
    );
  }

  renderCardholderCountryOfCitizenship() {
    const countryOfCitizenshipLabel = 'Country of Citizenship';
    const nonResidentAlienLabel = 'Are you a non-resident alien?';
    return (
      <div className="card--form__section">
        <div className="card--form__row width-100">
          <div className="card--form__col width-100">
            <label htmlFor="country-of-citizenship" style={{ marginBottom: 10 }}>
              {countryOfCitizenshipLabel}{' '}
            </label>
            <CountryOfCitizenshipFormWrapper
              compactUI
              countryOfCitizenshipLabel={countryOfCitizenshipLabel}
              hideHeader
              nonResidentAlienLabel={nonResidentAlienLabel}
            />
          </div>
        </div>
        <div className="card--form__row">
          <p style={{ marginRight: 10 }}>
            <i className="info-circle cursor-default" />
          </p>
          <p style={{ fontStyle: 'italic' }}>
            Federal regulation requires us to gather this information. It will only be used to
            verify your identity and will not impact your credit score.
          </p>
        </div>
      </div>
    );
  }

  renderCardholderDobAndSsnSection() {
    const { cardholder, onChangeCardholderAttr } = this.context;
    return (
      <div className="card--form__section">
        <div className="card--form__row">
          <div className="card--form__col">
            <label htmlFor="dob">
              Date of Birth
              <ReactInputMask
                id="dob"
                mask="99/99/9999"
                name="cardholder[dob]"
                onChange={onChangeCardholderAttr}
                pattern="\d{1,2}/\d{1,2}/\d{4}"
                placeholder="MM/DD/YYYY"
                required
                type="text"
                value={cardholder.dob || ''}
              />
            </label>
          </div>
          {!this.context.secondaryDocumentation && (
            <div className="card--form__col">
              <label htmlFor="ssn">
                Social Security Number
                <span className="card--form__input-addition">
                  <i
                    className="lock cursor-default"
                    data-placement="top"
                    data-template='<div class="tooltip" role="tooltip" style="width: 320px"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
                    data-toggle="tooltip"
                    style={{ marginRight: 5 }}
                    title="True Link Financial’s services use “bank level security” to communicate sensitive information. This includes site security with a 2048-bit key size, encryption using TLS 1.2, with a ECDHE_RSA with P-256 key exchange, and the AES_128_GCM cipher, and an HTTPS requirement for all connections."
                  />
                </span>
                <ReactInputMask
                  id="ssn"
                  mask="999-99-9999"
                  name="cardholder[ssn_raw]"
                  onChange={onChangeCardholderAttr}
                  placeholder="555-55-5555"
                  required
                  type="text"
                  value={cardholder.ssn || ''}
                />
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderCardAndAccountSection() {
    const { cardholder, onChangeCardholderAttr, programName } = this.context;
    return (
      <div className="card--form__section">
        <div className="card--form__row">
          <div className="card--form__col">
            <label htmlFor="card_last_4">
              Last 4 Digits of {programName} Card
              <ReactInputMask
                id="card_last_4"
                mask="9999"
                name="cardholder[card_last_4]"
                onChange={onChangeCardholderAttr}
                placeholder="9999"
                required
                type="text"
                value={cardholder.card_last_4 || ''}
              />
            </label>
          </div>
          <div className="card--form__col">
            <label htmlFor="nickname">
              Account Identifier
              <span className="card--form__input-addition">Optional</span>
              <input
                id="nickname"
                name="cardholder[nickname]"
                onChange={onChangeCardholderAttr}
                placeholder="Label or nickname (optional)"
                type="text"
                value={cardholder.nickname || ''}
              />
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderCardholderAgreementSection() {
    return (
      <div className="card--form__section" style={{ borderBottom: 0 }}>
        <div className="card--form__row" style={{ marginBottom: 0 }}>
          <div className="card--form__col width-100">
            <label
              className="multiline card--form__checkbox-label"
              htmlFor="verify_cardholder_agreement"
            >
              <input
                defaultChecked={false}
                id="verify_cardholder_agreement"
                name="cardholder_agreement"
                required
                type="checkbox"
              />
              <p>I agree to the {this.linkToCardholderAgreement()} associated with this card.</p>
            </label>
          </div>
        </div>
      </div>
    );
  }

  onChangeFormAttr(ev) {
    const state = {};
    const attr = $(ev.target).attr('id');
    state[attr] = ev.target.value;
    this.setState(state);
  }

  linkToCardholderAgreement() {
    const { msaLink } = this.context;
    return (
      <a href={msaLink} rel="noreferrer" target="_blank">
        Cardholder Agreement and Protection Service Agreement
      </a>
    );
  }

  cardholderAddress() {
    const { address } = this.context;
    return (
      <div>
        {address.street1}
        <br />
        {address.street2 ? (
          <div>
            {address.street2}
            <br />
          </div>
        ) : null}
        {address.city}, {address.state} {address.zip}
      </div>
    );
  }

  renderSecondaryDocumentationText() {
    const { secondaryDocumentation, programName } = this.context;
    if (secondaryDocumentation) {
      return (
        <p>
          {' '}
          <strong>
            {' '}
            By submitting this form, you are confirming that we have collected a non-expired photo
            ID and a valid secondary ID for this cardholder. This will activate the {
              programName
            }{' '}
            Card.{' '}
          </strong>
        </p>
      );
    }
  }

  render() {
    return (
      <PopUp {...this.getPopUpProps()}>
        <div className="card--form">
          <form id="account_list_activation_form">
            <div className="card--form__left">
              <div className="card--form__left__header">About the card user</div>
              <div className="card--form__left__body">
                <div className="card--form__left__section">
                  Please provide some basic information about the person using the card.
                </div>
                To activate this card, we will use the information provided to verify the
                cardholder’s identity as required by Homeland Security and the USA PATRIOT Act.
                <br />
                <br />
                If we are unable to verify the cardholder’s identity, we can activate the card by
                collecting two forms of identification. In that case you will receive an email with
                additional instructions.
              </div>
            </div>
            <div className="card--form__right">
              {this.renderSecondaryDocumentationText()}
              {this.renderCardholderNameSection()}
              {this.renderCardholderAddressSection()}
              {this.renderCardholderPhoneSection()}
              {this.renderCardholderCountryOfCitizenship()}
              {this.renderCardholderDobAndSsnSection()}
              {this.renderCardAndAccountSection()}
              {this.renderCardholderAgreementSection()}
            </div>
          </form>
        </div>
        {this.state.errorMessage && (
          <PopUp
            dismissBtnText="Close"
            header="Card Activation"
            id="card-activation-resp-modal"
            modalOpen
            onClose={this.closeErrorModal}
          >
            {this.state.errorMessage}
          </PopUp>
        )}
      </PopUp>
    );
  }
}

DashboardCardActivationForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  cardContext: PropTypes.bool,
};
