import PropTypes from 'prop-types';
import React from 'react';
import PayeeInputs from './PayeeInputs';
import PayeeName from './PayeeName';
import PayeeTaxIdInput from './PayeeTaxIdInput';
import PaymentMethodDropdown from 'react/shared/components/PaymentMethodDropdown';
import bindAll from 'react/shared/utils/bind_all';

//TODO: Update to functional component and use useRef
export default class DashboardPayeeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked:
        !!props.needs1099Form ||
        !!props.formData.needs1099Form ||
        props.formData.needs_1099_form === 'true',
    };

    bindAll(this);
  }

  supportedPaymentMethods() {
    return this.props.displayedPaymentMethods.filter(
      (e) => !['Card', 'Direct Debit', 'Exchange', ''].includes(e),
    );
  }

  toggleChecked(_event) {
    return this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  payeeName() {
    return (
      <PayeeName
        addError={this.props.addError}
        name={
          this.props.formData === 'edit' ? this.props.initialPayee.name : this.props.formData.name
        }
        parentError={{ name: true }}
        removeError={this.props.removeError}
      />
    );
  }

  payee1099() {
    return (
      <div className="new-form-field">
        <div className="new-form__label">Does this payee need a 1099 form?</div>
        <div className="new-form__data">
          <input
            checked={this.state.isChecked}
            name="needs1099Form"
            onChange={this.toggleChecked}
            type="checkbox"
            value={this.state.isChecked}
          />
        </div>
      </div>
    );
  }
  payeeDba() {
    return (
      <div className="new-form-field">
        <div className="new-form__label">
          <label htmlFor="payee_dba">Doing Business As</label>
        </div>
        <input
          className="form-control"
          defaultValue={this.props.formData.doingBusinessAs}
          id="payee_dba"
          name="doingBusinessAs"
          type="text"
        />
      </div>
    );
  }
  payeeTaxIdNumber() {
    return (
      <PayeeTaxIdInput
        addError={this.props.addError}
        parentError={{ name: true }}
        removeError={this.props.removeError}
        shouldValidate={this.state.isChecked}
        taxIdNumber={this.props.formData.taxIdNumber}
      />
    );
  }

  payeeId() {
    if (this.props.formType === 'edit') {
      return <input name="id" type="hidden" value={this.props.initialPayee.id} />;
    }
  }

  payeeInputs() {
    return (
      <div>
        <PaymentMethodDropdown
          displayedPaymentMethods={this.supportedPaymentMethods()}
          handlePaymentMethod={this.props.handlePaymentMethod}
          isEditable
          paymentMethod={this.props.paymentMethod}
        />
        <PayeeInputs
          addError={this.props.addError}
          formData={this.props.formType === 'edit' ? this.props.initialPayee : this.props.formData}
          paymentMethod={this.props.paymentMethod}
          removeError={this.props.removeError}
        />
      </div>
    );
  }

  render() {
    return (
      <form
        className={this.props.formType === 'edit' ? 'form-horizontal' : ''}
        id={this.props.formType === 'edit' ? 'edit_payee_form' : 'new_payee_form'}
        ref={(input) => (this.form = input)}
      >
        <input
          name={this.props.formType === 'edit' ? 'trustBeneficiarySlug' : 'trustBeneficiaryId'}
          type="hidden"
          value={this.props.clientSlug}
        />
        {this.payeeId()}
        {this.payeeName()}
        {this.payee1099()}
        {this.state.isChecked && this.payeeDba()}
        {this.state.isChecked && this.payeeTaxIdNumber()}
        {this.payeeInputs()}
      </form>
    );
  }
}

DashboardPayeeForm.propTypes = {
  formData: PropTypes.object,
  paymentMethod: PropTypes.string,
  handlePaymentMethod: PropTypes.func.isRequired,
  formType: PropTypes.oneOf(['new', 'edit']).isRequired,
  clientSlug: PropTypes.string,
  initialPayee: PropTypes.object,
  displayedPaymentMethods: PropTypes.array,
  addError: PropTypes.func,
  removeError: PropTypes.func,
  needs1099Form: PropTypes.bool,
};
