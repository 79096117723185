import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundFileGenerationForm from 'react/shared/card/components/BackgroundFileGenerationForm';
import DatePicker from 'react/shared/components/DatePicker';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import bindAll from 'react/shared/utils/bind_all';

//TODO: Update to functional component and use useRef
export default class TransactionReportForAllCards extends React.Component {
  static get propTypes() {
    return {
      organizationId: PropTypes.number.isRequired,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().subtract(2, 'day').toDate(),
      endDate: moment().subtract(1, 'day').toDate(),
    };

    bindAll(this);
  }

  handleChange(event) {
    if ($(event.target).hasClass('js-start-date')) {
      this.setState({ startDate: moment(event.target.value).toDate() });
    } else {
      this.setState({ endDate: moment(event.target.value).toDate() });
    }
  }

  render() {
    const { startDate, endDate } = this.state;
    const { organizationId } = this.props;
    const yesterday = moment().subtract(1, 'day').toDate();

    const invalidRange = moment(endDate).isBefore(moment(startDate));

    return (
      <div id="bulk_transaction_report" ref={(input) => (this.bulkTransactionReport = input)}>
        <div style={{ display: 'inline-block', marginRight: 10 }}>
          <DatePicker
            endDate={endDate}
            maxDate={yesterday}
            onChange={this.handleChange}
            startDate={startDate}
          />
        </div>
        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
          {invalidRange ? (
            <TrueLinkButton className="btn normal" disabled variant="none">
              Download as Spreadsheet
            </TrueLinkButton>
          ) : (
            <BackgroundFileGenerationForm
              buttonText="Download as Spreadsheet"
              data={{ start_date: startDate, end_date: endDate, organization_id: organizationId }}
              downloadDisabled={false}
              downloadRoute={RailsRoutes.cardholder_bulk_transaction_report_path}
              fileType="csv"
              largeNumberOfAccounts
              modalText="Please wait while your report is generated..."
            />
          )}
        </div>
        {invalidRange && (
          <p className="text-error" style={{ marginTop: 5 }}>
            Start Date must be before End Date
          </p>
        )}
      </div>
    );
  }
}
